<template>
  <Layout>
    <!-- <Header class="header"> -->
      <!-- <Breadcrumb>
        <BreadcrumbItem>系统资源</BreadcrumbItem>
      </Breadcrumb> -->
    <!-- </Header> -->
    <Content>
      <Table class="table-list" :loading="loading" :columns="columns" :data="data.list" no-data-text="没有符合查询条件的数据">
        <template slot-scope="{ row }" slot="action">
          <Button type="success" size="small" class="action-btn" :to="'/sys_manage/res/res_join_role/'+row.id">关联角色</Button>
        </template>
      </Table>
      <div class="page-nav">
        <Page 
          show-total
          :total="data.total"
          @on-change="changPageNum"
        />
      </div>
    </Content>
  </Layout>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
export default Vue.extend({
  data() {
    return {
      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 1,
      columns: [
        {
          title: '资源名称',
          key: 'resName',
          width: 200,
        },
        {
          title: '资源类型',
          key: 'type',
          width: 200,
        },
        {
          title: '资源模式',
          key: 'pattern',
        },
        {
          title: '操作',
          slot: 'action',
          width: 200,
        },
      ],
      data: {
        total: 0,
        list: [],
      },
    }
  },
  methods: {
    getData() {
      this.loading = true
      const { pageNum, pageSize } = this
      const params = { pageNum, pageSize }
      request.get('/api/sys/res/page', params)
      .then((data) => {
        this.data = data
        this.loading = false
      })
    },
    /* 改变pageNum */
    changPageNum(num) {
      this.pageNum = num;
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
})
</script>
<style scoped>
  .ivu-layout{
    background-color: transparent;
    padding: 0 10px;
  }
  
.action-btn{
  margin: 0 5px;
}
.ivu-table-wrapper {
    position: relative;
    border: none;
    border-right: 0;
    overflow: hidden;
}
::v-deep .ivu-table{
    background: transparent;
    color: #fff;
    font-size: 16px;
}
::v-deep .ivu-table:before{
    background-color: transparent;
}
::v-deep .ivu-table th{
    background: #fff;
    border-color: #00A0E9;
}
::v-deep .ivu-table-header{
    border-radius: 8px;
}
::v-deep .ivu-table-header thead tr th {
    background: #00A0E9;
    border-color: #00A0E9;
    color: #fff;
}
::v-deep .ivu-table td {
    background: transparent;
    border-color: #00A0E9;
}
::v-deep .ivu-table-cell {
    padding-right: 0;
    padding-left: 13px;
    display: block;
    text-align: center;
}
/* 分页 */
.page-nav {
    color: #fff;
    margin-top: 20px;
}
::v-deep .ivu-page-prev,::v-deep .ivu-page-next {
    background: transparent;
    border: 1px solid #0077EE;
}
::v-deep .ivu-page-item{
    background: transparent;
    border: 1px solid #0077EE;
}
::v-deep .ivu-page-item a{
    color: #fff;
}
::v-deep .ivu-page-item-active a{
    color: #0077EE;
}
/* 滚动 + 加载中 */
::v-deep .ivu-table-overflowY::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}
::v-deep .ivu-table-overflowY::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #0077EE;
}
::v-deep .ivu-table-overflowY::-webkit-scrollbar-track {
    border-radius: 5px;
    border: 1px solid #0077EE;
}
::v-deep .ivu-spin-fix{
    background-color: transparent;
}
::v-deep .ivu-table-wrapper>.ivu-spin-fix{
    border: none;
}
</style>

